import React from 'react';
import './UserInfo.css';


const UserInfo = (props) => {
    return (
      <ul className="pa-user-info__detail">
        <li className="pa-user-info__detail-list">
          <p className="pa-user-info__detail-list__head">
            Name
          </p>
          <p className="pa-user-info__detail-list__content">
            {props.user.name}
          </p>
        </li>
        <li className="pa-user-info__detail-list">
          <p className="pa-user-info__detail-list__head">
            Username
          </p>
          <p className="pa-user-info__detail-list__content">
            {props.user.username}
          </p>
        </li>
        <li className="pa-user-info__detail-list">
          <p className="pa-user-info__detail-list__head">
            Email
          </p>
          <p className="pa-user-info__detail-list__content">
            {props.user.email}
          </p>
        </li>
        <li className="pa-user-info__detail-list">
          <p className="pa-user-info__detail-list__head">
            Address
          </p>
          <div className="pa-user-info__detail-list__content">
            {props.user.address ?
            <React.Fragment>
              <p>{props.user.address['street']}</p>
              <p>{props.user.address['suite']}</p>
              <p>{props.user.address['city']}</p>
              <p>{props.user.address['zipcode']}</p>
              <p>{props.user.address['geo']['lat']}, {props.user.address['geo']['lng']}</p>
            </React.Fragment>
            : null}
          </div>
        </li>
        <li className="pa-user-info__detail-list">
          <p className="pa-user-info__detail-list__head">
            Phone
          </p>
          <p className="pa-user-info__detail-list__content">
            {props.user.phone}
          </p>
        </li>
        <li className="pa-user-info__detail-list">
          <p className="pa-user-info__detail-list__head">
            Website
          </p>
          <p className="pa-user-info__detail-list__content">
            {props.user.website}
          </p>
        </li>
        <li className="pa-user-info__detail-list">
          <p className="pa-user-info__detail-list__head">
            Company
          </p>
          <div className="pa-user-info__detail-list__content">
          {props.user.company ?
            <React.Fragment>
              <p>{props.user.company['name']}</p>
              <p>{props.user.company['catchPhrase']}</p>
              <p>{props.user.company['bs']}</p>
            </React.Fragment>
            : null}
          </div>
        </li>
      </ul>
    )
}

export default UserInfo;