import React from 'react';
import './Footer.css';


const Footer = () => {
    return (
      <div className="pa-footer">
        <div className="pa-footer__text container">
          <b>PhotoAlbum.</b> Created using React.<br></br>
          Rahadiyan Yuniar Rahmanda
        </div>
      </div>
    )
}

export default Footer;