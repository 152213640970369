import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from '../Home/Home';
import Album from '../Album/Album';
import User from '../User/User';
import FavoritePhoto from '../FavoritePhoto/FavoritePhoto';
import Header from '../elements/Header/Header';
import Footer from '../elements/Footer/Footer';

const App = () => {
  return(
    <BrowserRouter basename='/'>
      <React.Fragment>
        <Header />
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/album/:albumId" component={Album} exact />
          <Route path="/user/:userId" component={User} exact />
          <Route path="/favoritephoto" component={FavoritePhoto} exact />
        </Switch>
        <Footer />
      </React.Fragment>
    </BrowserRouter>
  )
}

export default App;
