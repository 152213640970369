import React, { Component } from 'react';
import {API_URL} from '../../config';
import AlbumElement from '../elements/AlbumElement/AlbumElement';
import SearchFilter from '../elements/SearchFilter/SearchFilter';
import SelectFilter from '../elements/SelectFilter/SelectFilter';
import './Home.css';

class Home extends Component{
  state = {
    albums: [],
    searchTerm: '',
    selectTerm: 0
  }

  componentDidMount(){
    const endpoint = `${API_URL}/albums`;
    this.fetchItems(endpoint);
  }

  fetchItems = (endpoint) => {
    fetch(endpoint)
      .then(result => result.json())
      .then(result => {
        this.setState({
          albums: [...this.state.albums, ...result]
        });
      });
  }

  searchItems = (searchTerm) => {
    let endpoint = '';
    this.setState({
        albums: [],
        searchTerm
    })

    if (searchTerm === ''){
        endpoint = `${API_URL}/albums`;
    } else {
        endpoint = `${API_URL}/albums?title_like=${searchTerm}`;
    }

    this.fetchItems(endpoint);
  }

  selectItems = (selectTerm) => {
    let endpoint = '';
    this.setState({
        albums: [],
        selectTerm
    })
    
    if (selectTerm === '0') {
      endpoint = `${API_URL}/albums`;
    } else {
      endpoint = `${API_URL}/albums?userId=${selectTerm}`;
    }

    this.fetchItems(endpoint);
  }

  render() {
    return(
      <React.Fragment>
        <div className="pa-heading container">
          <h1 className="pa-heading__title">
            Photo Album
          </h1>
          <p className="pa-heading__caption">
            Show Photo Album
          </p>
        </div>
        <div className="pa-filter container">
          <SearchFilter callback={this.searchItems} />
          <SelectFilter callback={this.selectItems} />
        </div>
        <div className="pa-album-grid container">
          <ul className="pa-album-grid__list">
            {this.state.albums.map ((element, i) => {
              return <AlbumElement
                      key={i}
                      albumId={element.id}
                      albumName={element.title}
                      userId={element.userId}
                      withUserLink={true}
                    />
            })}
          </ul>
        </div>
      </React.Fragment>
    )
  }

}

export default Home;