import React, { Component } from 'react';
import './FavoritePhoto.css';

class FavoritePhoto extends Component{

  state = {
    favoritePhotos: []
  }

  componentDidMount(){
    if (localStorage.getItem('FavoritePhotos')) {
      const favPhotoData = JSON.parse(localStorage.getItem('FavoritePhotos'));

      this.setState({
        favoritePhotos: favPhotoData
      });
    }
  }

  render() {
    return(
      <React.Fragment>
        <div className="pa-heading container">
          <h1 className="pa-heading__title">
            Favorite Photos
          </h1>
          <p className="pa-heading__caption">
            Show Your Favorite Photos
          </p>
        </div>
        <div className="pa-photo-grid container">
          
            { this.state.favoritePhotos.length > 0 ?
            <ul className="pa-photo-grid__list">
              {this.state.favoritePhotos.map ((element, i) => {
                return  <li key={i} className="pa-photo-grid__list-item">
                          <div className="pa-photo-grid__list-item__wrapper">
                            <img className="pa-photo-grid__list-item__image" src={element.thumbnailUrl} alt={element.photoName}/>
                            <p className="pa-photo-grid__list-item__title">
                                {element.photoName}
                            </p>
                            <p className="pa-photo-grid__list-item__album-head">
                                from album:
                            </p>
                            <p className="pa-photo-grid__list-item__album-name">
                                {element.albumName}
                            </p>
                          </div>
                        </li>
              })}
            </ul>
            : <div className="pa-photo-grid__list-no-item">No Favorite Photos Found</div>
            }

        </div>
      </React.Fragment>
    )
  }

}

export default FavoritePhoto;