import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {API_URL} from '../../config';
import PhotoElement from '../elements/PhotoElement/PhotoElement';
import './Album.css';

class Album extends Component{

  state = {
    photos: [],
    userName: null,
    userEmail: null,
    albumData: [],
  }

  componentDidMount(){
    const albumId = this.props.match.params.albumId;
    const endpoint = `${API_URL}/photos?albumId=${albumId}`;
    const albumName = this.props.location.albumName;

    if (albumName === undefined) {
      const endpointAlbumData = `${API_URL}/albums?id=${albumId}`;
      this.fetchAlbumData(endpointAlbumData)
    }

    this.fetchItems(endpoint);
  }

  getFavoritePhotos = () => {
    if (localStorage.getItem('FavoritePhotos')) {
      let favPhotoArray = [];
      let locStorageData = JSON.parse(localStorage.getItem('FavoritePhotos'));
      
      locStorageData.map((element, i) => {
        favPhotoArray.push(element.photoId);
      })

      const favButtons = document.querySelectorAll('.pa-photo-grid__list-item__button-favorite');

      for (let i = 0; i < favButtons.length; i++) {
        const getDataId = parseInt(favButtons[i].getAttribute("data-id"));
        
        if (favPhotoArray.includes(getDataId)) {
          favButtons[i].setAttribute("active", "true");
          favButtons[i].classList.add('pa-photo-grid__list-item__button-favorite--active');
        }
      }
    }
  }

  fetchAlbumData = (endpointAlbumData) => {
    fetch(endpointAlbumData)
    .then(result => result.json())
    .then(result => {
      this.setState({
        albumData: result[0]
      })
    }).catch(error => console.error('Error:', error));
  }

  fetchItems = (endpoint) => {
    fetch(endpoint)
    .then(result => result.json())
    .then(result => {
      this.setState({
        photos: result
      }, () => {
        const userId = this.props.location.userId;
        const endpoint = `${API_URL}/users?id=${userId}`;

        fetch(endpoint)
        .then(result => result.json())
        .then(result => {
            if (result.length > 0) {
              this.setState({
                userName: result[0]['name'],
                userEmail: result[0]['email']
              })
            }
            this.getFavoritePhotos();
        })
      })
      
    }).catch(error => console.error('Error:', error));
  }

  render() {
    return(
      <React.Fragment>
        <div className="pa-heading container">
          <h1 className="pa-heading__title">
            {this.props.location.albumName ? this.props.location.albumName : this.state.albumData.title} Album
          </h1>
          <div className="pa-heading__caption">
            <p className="pa-heading__caption-createdby">Created by: </p>
            <Link className="pa-heading__caption-username" to={{ pathname: `/user/${this.props.location.userId}`}}>
              {this.state.userName} ({this.state.userEmail})
            </Link>
          </div>
        </div>
        <div className="pa-photo-grid container">
          <ul className="pa-photo-grid__list">
            {this.state.photos.map ((element, i) => {
              return <PhotoElement
                      key={i}
                      albumName={this.props.location.albumName ? this.props.location.albumName : this.state.albumData.title}
                      photoId={element.id}
                      photoName={element.title}
                      thumbnailUrl={element.thumbnailUrl}
                    />
            })}
          </ul>
        </div>
      </React.Fragment>
    )
  }

}

export default Album;