import React, { Component } from 'react';
import './PhotoElement.css';

class PhotoElement extends Component{
  constructor(props) {
    super(props);
    this.state = {
      commentValue: '',
      comments:[]
    };
  }

  componentDidMount() {
    this.getPhotoComments()
  }

  handleFavoriteButton = (e) => {
    const checkFavButtonActive = e.target.getAttribute('active');

    if (checkFavButtonActive === 'false') {
      e.target.setAttribute("active", "true");
      e.target.classList.add('pa-photo-grid__list-item__button-favorite--active');
      this.setFavoritePhotoHandler();
    } else {
      // e.target.setAttribute("active", "false");
      // e.target.classList.remove('pa-photo-grid__list-item__button-favorite--active');
    }
  }

  setFavoritePhotoHandler = () => {
    const favPhotoData = [{
      photoId: this.props.photoId,
      photoName: this.props.photoName,
      thumbnailUrl: this.props.thumbnailUrl,
      albumName: this.props.albumName
    }]

    if (localStorage.getItem('FavoritePhotos')) {
      let locStorageData = JSON.parse(localStorage.getItem('FavoritePhotos'));

      const pushData = {
        photoId: this.props.photoId,
        photoName: this.props.photoName,
        thumbnailUrl: this.props.thumbnailUrl,
        albumName: this.props.albumName
      }
      
      locStorageData.push(pushData);
      localStorage.setItem('FavoritePhotos', JSON.stringify(locStorageData));
    } else {
      localStorage.setItem('FavoritePhotos', JSON.stringify(favPhotoData));
    }
  }

  getPhotoComments = () => {
    const photoIdProp = this.props.photoId

    if (localStorage.getItem('PhotoComments')) {
      let locStorageData = JSON.parse(localStorage.getItem('PhotoComments'));
      let arrByPhotoID = locStorageData.filter(filterByPhotoID);

      function filterByPhotoID(item) {
        if (item.photoId === photoIdProp) {
          return true
        } 
      }

      if (arrByPhotoID.length > 0) {
        this.setState({
          comments: arrByPhotoID
        })
      }
    }

  }

  saveComment = (commentValue) => {
    const commentData = [{
      photoId: this.props.photoId,
      comment: commentValue
    }]

    if (localStorage.getItem('PhotoComments')) {
      let locStorageData = JSON.parse(localStorage.getItem('PhotoComments'));

      const pushData = {
        photoId: this.props.photoId,
        comment: commentValue
      }
      
      locStorageData.push(pushData);
      localStorage.setItem('PhotoComments', JSON.stringify(locStorageData));
    } else {
      localStorage.setItem('PhotoComments', JSON.stringify(commentData));
    }
  }

  doChangeValue = (event) => {
    this.setState({
      commentValue: event.target.value
    })
  }

  doComment = (event) => {
    event.preventDefault()
    const commentWrapper = event.target.previousSibling;
    const commentValue = this.state.commentValue;

    if (commentValue.trim() === '') {
      this.setState({
        commentValue: ''
      })
    } else {
      var commentElem = document.createElement("li");
      var textnode = document.createTextNode(commentValue);
      commentElem.appendChild(textnode);
      commentWrapper.appendChild(commentElem);

      this.setState({
        commentValue: ''
      })

      this.saveComment(commentValue.trim())
    }
  }

  render() {
    return(
      <li className="pa-photo-grid__list-item">
        <div className="pa-photo-grid__list-item__wrapper">
          <img className="pa-photo-grid__list-item__image" src={this.props.thumbnailUrl} alt={this.props.photoName}/>
          <p className="pa-photo-grid__list-item__title">
              {this.props.photoName}
          </p>
          <div className="pa-photo-grid__list-item__button">
            <div active="false" onClick={this.handleFavoriteButton.bind(this)} data-id={this.props.photoId} className="pa-photo-grid__list-item__button-favorite" title="mark as favorite"></div>
          </div>
        </div>
        <div className="pa-photo__comment">
          <ul className="pa-photo__comment-list">
            {this.state.comments.map ((element, i) => {
              return <li key={i}>{element.comment}</li>
            })}
          </ul>
          <form onSubmit={this.doComment}>
            <label>
              <textarea placeholder="Write Comment..." value={this.state.commentValue} onChange={this.doChangeValue} />
            </label>
            <input className="pa-photo__comment-submit-button" type="submit" value="Submit" />
          </form>
        </div>
      </li>
    )
  }

}

export default PhotoElement;