import React from 'react';
import './AlbumElement.css';
import { Link } from 'react-router-dom';

const AlbumElement = (props) => {
    return(
      <li className="pa-album-grid__list-item">
        <Link className="pa-album-grid__list-item__title" to={{ pathname: `/album/${props.albumId}`, albumName: `${props.albumName}`, userId: `${props.userId}`}}>
            {props.albumName}
        </Link>
        {props.withUserLink === true ?
          <Link className="pa-album-grid__list-item__user" to={{ pathname: `/user/${props.userId}`, userName: `${props.userId}`}}>
            See User Info
          </Link>
        : null}

      </li>
    )
}

export default AlbumElement;