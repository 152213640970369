import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';


const Header = () => {
    return (
      <div className="pa-navbar">
        <nav className="pa-navbar__nav container">
          <Link className="pa-navbar__nav-logo" to="/">
            PhotoAlbum.
          </Link>
          {/* <a className="pa-navbar__nav-logo" href="/">
            PhotoAlbum.
          </a> */}
          <div className="pa-navbar__nav-left">
            {/* <a className="pa-navbar__nav-link" href="/">
              Home
            </a>
            <a className="pa-navbar__nav-link" href="/favoritephoto">
              My Favorite Photos
            </a> */}
            <Link className="pa-navbar__nav-link" to="/">
              Home
            </Link>
            <Link className="pa-navbar__nav-link" to="/favoritephoto">
              My Favorite Photos
            </Link>
          </div>
        </nav>
      </div>
    )
}

export default Header;