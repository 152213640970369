import React, {Component} from 'react';
import './SelectFilter.css';
import {API_URL} from '../../../config';

class SelectFilter extends Component{
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      users: []
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const endpoint = `${API_URL}/users`;
    this.fetchUsernameData(endpoint)
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();
    clearTimeout(this.timeout);

    this.timeout = setTimeout( () => {
        this.props.callback(this.state.value);
    })
  }

  fetchUsernameData(endpoint) {
    fetch(endpoint)
      .then(result => result.json())
      .then(result => {
        this.setState({
          users: result
        });
      })
  }

  render() {
    return (
      <div className="pa-filter__byusername">
        <form onSubmit={this.handleSubmit}>
          <label>
            <div className="pa-filter__byusername-head">Filter by Username</div>
            <select value={this.state.value} onChange={this.handleChange} className="pa-filter__byusername-select">
              <option key={0} value={0}>All Username</option>
              {this.state.users.map((element, i) => {
                return <option key={i+1} value={element.id}>{element.username}</option>
              })}
            </select>
          </label>
          <input type="submit" value="Filter" className="pa-filter__byusername-button"/>
        </form>  
      </div>
    );
  }
}

export default SelectFilter;