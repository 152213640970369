import React, {Component} from 'react';
import './SearchFilter.css';

class SearchFilter extends Component{
    state ={
        value:''
    }

    timeout = null;

    doSearch = () => {
      clearTimeout(this.timeout);

      this.timeout = setTimeout( () => {
          this.props.callback(this.state.value);
      })
    }

    doChangeValue = (event) => {
      this.setState({
        value: event.target.value
      })
    }

    render(){
        return(
            <div className="pa-filter__bytitle">
              <div className="pa-filter__bytitle-head">Filter by Album Name</div>
              <input
                type="text"
                className="pa-filter__bytitle-input"
                placeholder="Input Album Name"
                onChange={this.doChangeValue}
                value={this.state.value}
              />
              <button className="pa-filter__bytitle-button" onClick={this.doSearch}>Filter</button>
            </div>
        )
    }
}

export default SearchFilter;