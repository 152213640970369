import React, { Component } from 'react';
import {API_URL} from '../../config';
import AlbumElement from '../elements/AlbumElement/AlbumElement';
import UserInfo from '../elements/UserInfo/UserInfo';
import './User.css';

class User extends Component{

  state = {
    user: [],
    albums: []
  }

  componentDidMount() {
    const userId = this.props.match.params.userId;
    const endpoint = `${API_URL}/users?id=${userId}`;

    this.fetchItems(endpoint);
  }

  fetchItems = (endpoint) => {
    fetch(endpoint)
    .then(result => result.json())
    .then(result => {
      this.setState({
        user: result[0]
      }, () => {
        const userId = this.props.match.params.userId;
        const endpoint = `${API_URL}/albums?userId=${userId}`;

        fetch(endpoint)
        .then(result => result.json())
        .then(result => {
          this.setState({
            albums: result
          })
        })
      })
      
    }).catch(error => console.error('Error:', error));
  }

  render() {

    return(
      <React.Fragment>
        <div className="pa-heading container">
          <h1 className="pa-heading__title">
            {this.state.user.name} User Information
          </h1>
        </div>
        <div className="pa-user-info container">
          <h2 className="pa-user-info__title">
            User Information
          </h2>
          <UserInfo user={this.state.user} address={this.state.user.address}/>
        </div>
        <div className="pa-album-grid container">
          <h2 className="pa-album-info__title">
            {this.state.user.name}'s Albums
          </h2>
          <ul className="pa-album-grid__list">
            {this.state.albums.map ((element, i) => {
              return <AlbumElement
                      key={i}
                      albumId={element.id}
                      albumName={element.title}
                      userId={element.userId}
                      withUserLink={false}
                    />
            })}
          </ul>
        </div>
      </React.Fragment>
    )
  }

}

export default User;